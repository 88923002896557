.container {
    display: grid;
    grid-template-areas:
        "i"
        "t";
    grid-auto-rows: auto;
    grid-template-columns: 100%;
}

.content {
    grid-area: t;
}

.image {
    grid-area: i;
}

/* md */
/* @media (min-width: 768px) {

} */

/* lg */
@media (min-width: 1024px) {
    .container {
        grid-template-areas: none;
        grid-template-columns: none;
    }

    .imageLeft {
        grid-template-areas: "i t";
        grid-template-columns: auto 1fr;
    }

    .imageRight {
        grid-template-areas: "t i";
        grid-template-columns: 1fr auto;
    }
}