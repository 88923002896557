.container {
   background-color: #BCCDD7;
   display: flex;
   justify-content: space-evenly;
   padding: 50px 0 25px 0;
   
}

.MaryPets {
    text-align: center;
    max-width: 30%;
}

.container label {
    font-size: 20px;
}

.catImage {
    display: block;
    padding-bottom: 20px;
    max-width: 100%;
}

@media (max-width: 1000px) {
    .container label {
        font-size: 15px;
    }
}

@media (max-width: 600px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .MaryPets {
        max-width: 100%;
    }

    .catImage {
        max-width: 75%;
        margin: auto;
        padding-top: 20px;
    }
}