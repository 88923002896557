.fadingIn {
    animation-name: fadeIn;
}

.fadingOut {
    animation-name: fadeOut;
}

.fadingIn, .fadingOut {
    animation-timing-function: linear;
    animation-duration: 0.3s;
    animation-delay: 0;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}