@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --text-primary: black;
    --text-secondary: white;
    --text-tertiary: #acacac; /* Used in CustomFooter */
    --btn-primary: #d16d79;
    --btn-secondary: #d45d6b;
    --bg-light: #bccdd7;
    --bg-dark: #252f48;
    --bg-footer: #455f6c;
}

.active {
    text-decoration: underline;
}

